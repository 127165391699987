import * as logoSrc from "../../../SharedResources/images/R1_logo.svg";

function formatEml(s1, s2) {
    return `<a href="mailto:${s1}@${s2}.com">${s1}@${s2}.com</a>`;
}

if (document.getElementById("cnt1")) {
    document.getElementById("cnt1").innerHTML = formatEml("PMHelp", "r1rcm");
}
if (document.getElementById("cnt2")) {
    document.getElementById("cnt2").onclick = () => {
        history.back();
    };
}

(document as any).getElementById("logo").src = logoSrc;
